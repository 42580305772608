// ---------------------------------------------------- VALIDATION //
_validator = $('#login-user').validate({
  ignore: [],
  onfocusout(element) {
    $(element).valid();
  },
  errorPlacement() {
    return true;
  },
  focusCleanup: false,
  rules: {
    email: {
      required: true,
    },
    password: {
      required: true,
    },
  },
  messages: {
    email: {
      required: 'Email is required',
    },
    password: {
      required: 'Password is required',
    },
  },
  // ---------------------------------------------------- FORM SUBMIT HANDLER //
  submitHandler() {
    $('.submit-btn').prop('disabled', true);

    $.ajax({
      url: '/ajax/login/verify_login',
      type: 'POST',
      dataType: 'json',
      data: $('#login-user').serialize(),
    })
      .done(response => {
        if (!response.err) {
          const redirect = $('#redirect').val();
          window.location = redirect;
        }
        else if (response.msg === 'ip_blacklist') {
          location.reload();
        }
        else {
          $.notify({ message: response.msg }, { type: 'danger', newest_on_top: true });
        }
      })
      .fail(response => {
        $.notify({ message: response.msg }, { type: 'danger', newest_on_top: true });
      })
      .always(() => {
        $('.submit-btn').prop('disabled', false);
        initGrecaptcha();
      });
  },
});

// ---------------------------------------------------- INIT reCAPTCHA //
const initGrecaptcha = () => {
  grecaptcha.execute(gRecaptchaKey, { action: 'beta' }).then(token => {
    $('[name="g-recaptcha-response"]').val(token);
  }, reason => {
    console.warn(reason);
  });
};

grecaptcha.ready(() => {
  initGrecaptcha();
});
